(function() {
  angular.module("app").directive("order", orderController);

  function orderController(
    convertcsv,
    orders,
    themes,
    cases,
    $routeParams,
    $q,
    $window,
    collections,
    $timeout,
    $rootScope,
    $compile,
    $base64,
    assets,
    funeralHomes,
    $http,
    products,
    orderProducts,
    layouts,
    orderLayouts,
    pages,
    funeralHomeSettings,
    funeralHomeLogo,
    $location,
    customThemes,
    helpers,
    convertImage,
    socket,
    user,
    funeralHomeDefaultLayouts,
    $route,
    dom,
    domHelpers
  ) {
    return {
      templateUrl: "pages/order/order.html",
      scope: true,
      link: function(scope) {
        var vm = scope;

        angular.extend(scope, {
          order: {},
          tab: "case",
          queryType: "active",
          getArchivedCases: getArchivedCases,
          getActiveCases: getActiveCases,
          archiveCase: archiveCase,
          unarchiveCase: unarchiveCase,
          showArchiveCase: showArchiveCase,
          showUnarchiveCase: showUnarchiveCase,
          addPin: addPin,
          removePin: removePin,
          pinned: [],
          newCase: false,
          categories: null,
          showSlider: false,
          importMessage: null,
          createCase: createCase,
          orderReverse: false,
          verses: [],
          selectVerse: selectVerse,
          filter: {
            archived: false
          },
          loadingPrint: false,
          step: 1,
          navigateTabs: navigateTabs,
          back: back,
          idSelectedCase: null,
          setSelectedCase: setSelectedCase,
          selectTheme: selectTheme,
          cases: [],
          selectCase: selectCase,
          selectProduct: selectProduct,
          selectSize: selectSize,
          done: done,
          printProduct: printProduct,
          layoutAlert: layoutAlert,
          layoutConfirm: layoutConfirm,
          layoutAlertDismiss: layoutAlertDismiss,
          selectedProduct: null,
          hasSelectedProducts: hasSelectedProducts,
          deactivateOtherCollections: deactivateOtherCollections,
          sortValue: "-sortable_created_at",
          descending: true,
          removeProduct: removeProduct,
          editCase: editCase,
          hasPlainProducts: hasPlainProducts,
          hasPreprintedProducts: hasPreprintedProducts,
          themetab: null,
          importCSV: importCSV,
          setCollectionTab: setCollectionTab,
          archiveToggle: "recent",
          case: {},
          alertText: "",
          selectedTheme: null,
          themeCategory: null,
          showCreateCaseModal: showCreateCaseModal,
          newCaseName: null,
          funeralHomeThemes: null,
          selected: false,
          showPrintInfo: showPrintInfo,
          printInfo: {},
          resetPrint: resetPrint,
          getFuneralHomes: getFuneralHomes,
          home: {},
          printSettings: {},
          defaultTemp: null,
          productPages: null,
          printSettings: {},
          themeScroll: themeScroll,
          funeralHome: [],
          layoutDefaults: [],
          layoutAvailability: [],
          printSettings: {},
          casefields: [],
          builtProduct: false,
          hasSelectedTheme: false,
          layoutHasBeenSaved: false,
          themes: [],
          collection: [],
          products: [],
          addProductToOrder: addProductToOrder,
          orderProducts: [],
          changeCollection: changeCollection,
          newCollection: {},
          pinnedThemes: [],
          selectPinnedTheme: selectPinnedTheme,
          showOneCase: false,
          productLayouts: [],
          watermarks: [],
          orderPageSet: {},
          casePage: 1,
          casePages: [],
          funeralHomeSettings: {},
          refreshSocket: refreshSocket,
          gettingStep: true,
          building: false,
          productLoading: false,
          printPrompt: printPrompt,
          formatModalMessage: null,
          isBeta: false,
          addExtraPagesToOrder: addExtraPagesToOrder,
          addFrontInsert: addFrontInsert,
          isFrontSelected: isFrontSelected,
          hasFrontInsert: false,
          extraPages: [],
          printOffsets: {},
          stationaryProducts: [],
          candleProducts: [],
          serviceProducts: [],
        });

        init();

        function init() {
          loadOrderPage();
          detectFileUpload();
          vm.isBeta = isBeta;
        }

        //ability to set url without reload or resetting controller, this will make it so that when a user does refresh the order page loads correctly, routeParams will not be available until refresh
        const path = function(path, reload) {
          if (reload === false) {
            var lastRoute = $route.current;
            var un = $rootScope.$on("$locationChangeSuccess", function() {
              $route.current = lastRoute;
              un();
            });
          }
          return $location.path.apply($location, [path]);
        };

        function loadOrderPage() {
          vm.availableCases = [];
          vm.order.case_id = $routeParams.caseId;
          vm.order.id = $routeParams.orderId;

          if (orders.editCase) {
            getSocket($routeParams.caseId).then(data => {
              if (data.success) {
                vm.caseId = $routeParams.caseId;
                vm.showOneCase = true;
                cases.get(vm.order.case_id).then(caseResponse => {
                  vm.availableCases.push(caseResponse.data);
                  assignCase(caseResponse.data)
                  cases
                    .getAllCaseCustomFields(vm.order.case_id)
                    .then(customFieldResponse => {
                      $rootScope.$emit("opencase", {
                        case_details: caseResponse.data,
                        custom_fields: customFieldResponse.data
                      });
                      vm.gettingStep = false;
                    });
                });
                getOrderById($routeParams.orderId);
              } else {
                lockedSocket(data);
              }
            });
          } else if (
            !orders.editCase &&
            $routeParams.caseId &&
            $routeParams.orderId
          ) {
            getSocket($routeParams.caseId).then(data => {
              if (data.success) {
                vm.caseId = $routeParams.caseId;
                vm.showOneCase = true;
                cases.get($routeParams.caseId).then(response => {
                  vm.availableCases.push(response.data);
                  assignCase(response.data)
                });
                getOrderById($routeParams.orderId);
              } else {
                lockedSocket(data);
              }
            });
          } else if (
            !orders.newOrder &&
            !$routeParams.caseId &&
            !$routeParams.orderId
          ) {
            $location.path("dashboard");
          } else {
            getActiveCases();
            vm.gettingStep = false;
          }
          if (!funeralHomeSettings.settings) {
            funeralHomeSettings.get().then(response => {
              vm.funeralHomeSettings = response.data;
            });
          } else {
            vm.funeralHomeSettings = funeralHomeSettings.settings;
          }
        }

        function assignCase(response) {
          vm.case = response;
          if(vm.case.services) {
            vm.case.services = vm.case.services.map((service, index) => {
              service.date = moment.utc(service.date).format('LL')
              service.index = index
              service.type = service.type.charAt(0).toUpperCase() + service.type.slice(1)
              service.default === true && (vm.case.service_date = service.date)
              return service;
            })
            vm.case.visitations = vm.case.services.filter(item => item.type === 'Visitation')
            // vm.case.visitations.map((visitation, index) => {
            //   visitation.index = index
            //   return visitation
            // })
            vm.case.justServices = vm.case.services.filter(item => item.type === 'Service')
            // vm.case.justServices.map((service, index) => {
            //   service.index = index
            //   return service
            // })
          }
          if(vm.case.additional_family) {
            vm.case.other_family = vm.case.additional_family.filter(item => item.type === 'other_family')
            vm.case.preceded_by = vm.case.additional_family.filter(item => item.type === 'preceded_by')

            vm.case.other_family_combined = vm.case.other_family.map(item => {
              return `${item.name}, ${item.relation}`
            })
            vm.case.preceded_by_combined = vm.case.preceded_by.map(item => {
              return `${item.name}, ${item.relation}`
            })
          }
        }

        function getOrderById(id) {
          orders.getOrderById(id).then(response => {
            vm.order = response.data;
            getCollections();
            getCollectionProducts().then(() => {
              if (vm.order.theme_id) {
                themes.getThemeById(vm.order.theme_id).then(response => {
                  vm.selectedTheme = response.data;
                  goToStep();
                });
              } else {
                getThemeCategories();
                goToStep();
              }
            });
          });
        }

        function getActiveCases(page) {
          page ? (vm.casePage = page) : (vm.casePage = 1);
          vm.availableCases = [];
          vm.casePages = [];
          vm.queryType = "active";
          cases.getAll(page - 1 || 0).then(response => {
            const range = Math.ceil(response.data.total / 20);
            for (let i = 1; i <= range; i++) {
              vm.casePages.push(i);
            }
            vm.availableCases = response.data.cases;
          });
        }

        function getArchivedCases(page) {
          page ? (vm.casePage = page) : (vm.casePage = 1);
          vm.availableCases = [];
          vm.casePages = [];
          vm.queryType = "archived";
          cases.getArchivedCases(page - 1 || 0).then(response => {
            const range = Math.ceil(response.data.total / 20);
            for (let i = 1; i <= range; i++) {
              vm.casePages.push(i);
            }
            vm.availableCases = response.data.cases;
          });
        }

        function showArchiveCase(userCase) {
          vm.archiveId = userCase.id;
          $("#archive-modal").modal("show");
        }

        function showUnarchiveCase(userCase) {
          vm.archiveId = userCase.id;
          $("#un-archive-modal").modal("show");
        }

        function archiveCase() {
          cases.archiveCase(vm.archiveId).then(response => {
            getActiveCases();
            $("#archive-modal").modal("hide");
            $("#un-archive-modal").modal("hide");
          });
        }

        function unarchiveCase() {
          cases.unarchiveCase(vm.archiveId).then(response => {
            getArchivedCases();
            $("#archive-modal").modal("hide");
            $("#un-archive-modal").modal("hide");
          });
        }

        function selectCase(selectedCase) {
          getSocket(selectedCase.id).then(data => {
            if (data.success) {
              if ($rootScope.duplicateOrder) {
                let payload = {
                  order_id: $rootScope.duplicateOrder.id,
                  case_id: selectedCase.id
                };
                vm.duplicating = true;
                orders.duplicateOrder(payload).then(response => {
                  vm.order = response.data;
                  localStorage.setItem("order_id", vm.order.id);
                  vm.orderUpdated = true;
                  delete $rootScope.duplicateOrder;
                  path(`order/${vm.order.id}/${vm.order.case_id}`, false);
                  getOrderById(vm.order.id);
                });
              } else if (!vm.order.id && !localStorage.order_id) {
                vm.order.case_id = selectedCase.id;
                createOrder();
              } else if (vm.order.id) {
                vm.order.case_id = selectedCase.id;
                updateOrder();
              } else {
                vm.order.id = localStorage.order_id;
                vm.order.case_id = selectedCase.id;
                updateOrder();
              }
              cases.get(selectedCase.id).then(response => {
                assignCase(response.data)
              });
              vm.newCase = true;
            } else {
              lockedSocket(data);
            }
          });
        }

        function createOrder() {
          vm.orderUpdated = false;
          orders.create(vm.order).then(response => {
            vm.order = response.data;
            localStorage.setItem("order_id", vm.order.id);
            vm.orderUpdated = true;
            getCollections();
            //set the url to be the correct url so a refresh loads order tabs
            path(`order/${vm.order.id}/${vm.order.case_id}`, false);
          });
        }

        function updateOrder() {
          vm.orderUpdated = false;
          const id = vm.order.id;
          const order = Object.assign({}, vm.order);
          delete order.id;
          delete order.archived;
          delete order.funeral_home_id;
          delete order.service_start_date
          delete order.service_end_date
          delete order.selected_for_service
          orders.update(id, order).then(response => {
            if (response.status == 200) {
              vm.order = response.data;
              localStorage.setItem("order_id", vm.order.id);
              vm.orderUpdated = true;
            }
          });
        }

        function createCase() {
          cases
            .create({
              name_of_deceased: vm.newCaseName
            })
            .then(caseResponse => {
              vm.order.case_id = caseResponse.data.id;
              vm.availableCases.push(caseResponse.data);
              assignCase(caseResponse.data)
              if ($rootScope.duplicateOrder) {
                let payload = {
                  order_id: $rootScope.duplicateOrder.id,
                  case_id: caseResponse.data.id
                };
                vm.duplicating = true;
                orders.duplicateOrder(payload).then(response => {
                  vm.order = response.data;
                  localStorage.setItem("order_id", vm.order.id);
                  vm.orderUpdated = true;

                  path(`order/${vm.order.id}/${vm.order.case_id}`, false);
                  getOrderById(vm.order.id);
                });
              } else if (vm.order.id) {
                orders
                  .update(vm.order.id, {
                    case_id: caseResponse.data.id
                  })
                  .then(updateOrderResponse => {
                    vm.order = updateOrderResponse.data;
                  });
              } else {
                vm.order.id = undefined;
                orders.create(vm.order).then(createOrderResponse => {
                  vm.order = createOrderResponse.data;
                });
              }
              $rootScope.$emit("opencase", { case_details: caseResponse.data });
              $("#case-modal").modal("show");
              vm.newCaseName = null;
              vm.case.created_at = moment.utc(vm.case.created_at).format('LL')
            });
        }

        ////  NAVIGATION  ////

        function goToStep() {
          if (orders.editCase) {
            vm.tab = "case";
            orders.editCase = false;
          } else {
            if (!vm.order.case_id) {
              vm.tab = "case";
              vm.gettingStep = false;
              vm.duplicating = false;
            } else if (!hasSelectedProducts()) {
              getCollections();
              vm.tab = "products";
              vm.gettingStep = false;
              vm.duplicating = false;
            } else if (!vm.order.theme_id) {
              getCollections();
              vm.tab = "theme";
              vm.gettingStep = false;
              vm.duplicating = false;
            } else {
              collections.get(vm.order.collection_id).then(response => {
                vm.collectiontab = response.data;
                getCollectionProducts().then(response => {
                  vm.productLoading = false;
                  filterProducts();
                  vm.tab = "customize";
                  vm.gettingStep = false;
                  vm.duplicating = false;
                });
              });
            }
          }
        }

        function navigateTabs(clickedTab) {
          vm.productLoading = false;
          const hasProduct = orderHasProduct();
          const hasTheme = orderHasTheme();
          const allTabs = ["case", "products", "theme", "customize"];
          $window.scrollTo(0, 0);
          if (hasProduct && hasTheme) {
            filterProducts();
          }
          cases.currentCase.id
            ? ( assignCase(cases.currentCase))
            : cases
                .get(vm.order.case_id)
                .then(response => (assignCase(response.data)));
          if (clickedTab == "next") {
            clickedTab = allTabs[allTabs.indexOf(vm.tab) + 1];
          }

          const currentTabIndex = allTabs.indexOf(vm.tab);
          const clickedTabIndex = allTabs.indexOf(clickedTab);

          if (vm.order.case_id && hasProduct && hasTheme) {
            clickedTab == "customize"
              ? (vm.tab = clickedTab)
              : (vm.tab = clickedTab);
          } else if (vm.tab == "case") {
            if (vm.order.case_id) {
              if (clickedTab == "products") {
                vm.tab = clickedTab;
              } else if (clickedTab == "theme") {
                if (!hasProduct) {
                  vm.alertText = "Please select one or more products";
                  $("#alert-modal-steps").modal("show");
                  vm.tab = "products";
                } else {
                  vm.tab = clickedTab;
                }
              } else if (clickedTab == "customize") {
                if (!hasTheme) {
                  if (!hasProduct) {
                    vm.alertText = "Please select one or more products";
                    $("#alert-modal-steps").modal("show");
                    vm.tab = "products";
                  } else {
                    vm.alertText = "Please select a theme first";
                    $("#alert-modal-steps").modal("show");
                    vm.tab = "theme";
                  }
                } else {
                  vm.tab = "customize";
                }
              }
            } else {
              vm.alertText = "Please select a case";
              $("#alert-modal-steps").modal("show");
            }
          } else if (vm.tab == "products") {
            if (hasProduct) {
              if (
                clickedTabIndex < currentTabIndex ||
                clickedTabIndex == currentTabIndex + 1
              )
                vm.tab = clickedTab;
              if (clickedTab == "customize") {
                if (!hasTheme) {
                  vm.alertText = "Please select a theme first";
                  $("#alert-modal-steps").modal("show");
                  vm.tab = "theme";
                } else {
                  vm.tab = "customize";
                }
              }
            } else if (clickedTabIndex < currentTabIndex) {
              vm.tab = clickedTab;
            } else {
              vm.alertText = "Please select one or more products";
              $("#alert-modal-steps").modal("show");
            }
          } else if (vm.tab == "theme") {
            if (hasTheme) {
              vm.tab = clickedTab;
            } else if (clickedTabIndex < currentTabIndex) {
              vm.tab = clickedTab;
            } else {
              vm.alertText = "Please select a theme";
              $("#alert-modal-steps").modal("show");
            }
          } else {
            if (vm.order.theme_id) {
              if (clickedTab) {
                vm.tab = clickedTab;
              } else {
                vm.tab = "customize";
              }
            } else {
              vm.alertText = "Please select a theme";
              $("#alert-modal-steps").modal("show");
            }
          }
        }

        function back() {
          if (vm.tab == "products") {
            vm.tab = "case";
          } else if (vm.tab == "theme") {
            vm.tab = "products";
          } else {
            vm.tab = "theme";
          }
        }

        ////  PRODUCTS TAB  ////

        function getCollections() {
          collections.getAll().then(response => {
            vm.collections = response.data;
            if (vm.order.collection_id) {
              vm.collections.forEach(collection => {
                if (collection.id == vm.order.collection_id) {
                  vm.collectiontab = collection;
                }
              });
              getCollectionProducts();
            } else {
              vm.collectiontab = vm.collections[0];
              vm.order.collection_id = vm.collectiontab.id;
              updateOrder();
              getCollectionProducts();
            }
          });
        }

        function getCollectionProducts() {
          // FIXME: This is loading thrice on refresh or when order first loads
          let deferred = $q.defer();

          vm.products = [];
          let collection = vm.order.collection_id;

          collection
            ? (collection = vm.order.collection_id)
            : (collection = vm.collectiontab.id);

          let payload = {
            order_id: vm.order.id,
            collection_id: collection
          };

          products.getByCollection(collection).then(response => {
            vm.showThese = [];
            const stationarySort = ['Service Records', 'Church Bulletins', 'Trifolds', 'Prayer Cards', 'Bookmarks', 'Register Book', 'Thank You Cards', 'Clergy Records', 'Grave Markers']; 
            const candleSort = ['Designer Square Candle', 'Designer Round Candle', 'Small Candle', 'Large Candle'] 
            const serviceSort = ['Picture Frame', 'Directory Signs', 'Memory Box', 'Memorial Collage', '16 x 20 Collage', 'Register Book: Front Insert', 'Register Book: Extra Pages']
            
            const notUndefined = anyValue => typeof anyValue !== 'undefined'  
            vm.stationaryProducts = stationarySort.map(item => {
              return response.data.filter(product => product.name === item)[0]
            }).filter(notUndefined)
            vm.candleProducts = candleSort.map(item => {
              return response.data.filter(product => product.name === item)[0]
            }).filter(notUndefined)
            vm.serviceProducts = serviceSort.map(item => {
              return response.data.filter(product => product.name === item)[0]
            }).filter(notUndefined)

            vm.products = response.data
            
            orderProducts.get(payload).then(response => {
              vm.orderProducts = response.data;
              for (let i in vm.orderProducts) {
                for (let k in vm.products) {
                  if (
                    vm.orderProducts[i].product_id == vm.products[k].id &&
                    vm.orderProducts[i].selected
                  ) {
                    vm.products[k].selected = true;
                  }
                }
              }
              deferred.resolve(true);
            });
          });
          return deferred.promise;
        }

        async function setCollectionTab(collection) {
          await resetServiceThemeValues();
          let selected = false;
          vm.newCollection = collection;

          if (vm.orderProducts.length) {
            vm.orderProducts.forEach(item => {
              if (item.selected) selected = true;
            });
          }

          /*
            If order has colleciton and user is tries changing collection:
            a) warn them about resetting the products and theme associate with
               their order if they have already made selections, and then reset
               all values if they opt in
            b) or if they don't have any selected products immediately reset
               all theme values to avoid old theme values from accidentally
               persisting
          */

          if (collection.id !== vm.order.collection_id && selected) {
            $("#tab-alert").modal("show");
          } else if (collection.id !== vm.order.collection_id) {
            await resetControllerThemeValues();
            getCollectionProducts();
          }
        }

        function resetServiceThemeValues() {
          return new Promise((resolve, reject) => {
            themes.categories = []
            themes.pinnedThemes = []
            resolve('Service theme values reset')
          })
        }

        function resetControllerThemeValues() {
          return new Promise((resolve, reject) => {
            vm.order.theme_id = null;
            vm.selectedTheme = null;
            vm.hasSelectedTheme = false;
            vm.collectiontab = vm.newCollection;
            vm.order.collection_id = vm.newCollection.id;
            updateOrder();
            resolve("Controller theme values reset and order updated");
          });
        }

        async function changeCollection() {
          await resetControllerThemeValues();
          getCollectionProducts();
          $("#tab-alert").modal("hide");
        }

        function addProductToOrder(product) {
          let alreadySelected = false;
          let orderProduct = {};
          if (vm.orderProducts.length) {
            vm.orderProducts.forEach(item => {
              if (item.product_id == product.id) {
                alreadySelected = true;
                orderProduct = Object.assign({}, item);
              }
            });
          }
          if (product.selected && !alreadySelected) {
            let newProduct = Object.assign({}, product);
            newProduct.order_id = vm.order.id;
            newProduct.collection_id = vm.collectiontab.id;
            newProduct.product_id = product.id;
            delete newProduct.id;
            delete newProduct.archived;
            orderProducts.create(newProduct).then(response => {
              vm.orderProducts.push(response.data.product);
            });
            if (product.name == "Register Book") {
              let cover_page = vm.products.find(item => {
                return item.name === "Register Book: Front Insert";
              });
              if (cover_page) {
                let newCoverProduct = Object.assign({}, cover_page);
                newCoverProduct.order_id = vm.order.id;
                newCoverProduct.collection_id = vm.collectiontab.id;
                newCoverProduct.product_id = cover_page.id;
                newCoverProduct.selected = true;
                delete newCoverProduct.id;
                delete newCoverProduct.archived;
                orderProducts.create(newCoverProduct).then(response => {
                  vm.orderProducts.push(response.data.product);
                });
              }
            }
          } else {
            if (orderProduct) {
              orderProduct.selected = product.selected;
              const id = orderProduct.id;
              let payload = Object.assign({}, orderProduct);
              delete payload.available;
              delete payload.id;
              delete payload.product_id;
              delete payload.archived;
              vm.orderProducts.map(op => {
                if (op.id == orderProduct.id) {
                  op.selected = orderProduct.selected;
                }
              });
              orderProducts.update(id, payload).then(response => {
                if (orderProduct.name === "Register Book") {
                  const frontInsert = vm.products.filter(item => {
                    return item.name === "Register Book: Front Insert";
                  });
                  if (frontInsert.length > 0) {
                    orderProduct.selected
                      ? (frontInsert[0].selected = true)
                      : (frontInsert[0].selected = false);
                    addProductToOrder(frontInsert[0]);
                  }
                }
              });
            }
          }
        }

        function addExtraPagesToOrder() {
          let product = vm.products.find(item => {
            return item.name == "Register Book: Extra Pages";
          });
          let newProduct = Object.assign({}, product);
          newProduct.order_id = vm.order.id;
          newProduct.selected = true;
          newProduct.collection_id = vm.collectiontab.id;
          newProduct.product_id = product.id;
          delete newProduct.id;
          delete newProduct.archived;
          orderProducts.create(newProduct).then(response => {
            vm.orderProducts.push(response.data.product);
            filterProducts(response.data.product);
          });
        }

        ////  THEMES TAB  ////
        /*
        |--------------------------------------------------------------------------
        | Reducing Reload on Themes
        |--------------------------------------------------------------------------
        | If we store themes in the theme service once they are queried, then
        |  we can load the page from there rather than doing another query.
        |  We will need to set themes.category to [] when the user switches
        |  categories.
        |
        */
        function getThemeCategories() {
          vm.categories = []
          vm.pinnedThemes = []

          if(themes.categories.length && themes.categories[0].collection_id === vm.order.collection_id){
            vm.categories = themes.categories
            vm.pinnedThemes = themes.pinnedThemes
            getCustomThemes()
          } else {
            themes.getThemeCategoriesByCollection(vm.order.collection_id).then(response => {
              vm.categories = response.data
              themes.getPinnedThemes(vm.collectiontab.id).then(resp => {
                vm.pinnedThemes = resp.data
                getCustomThemes()
              })
            })
          }
        }

        function getCustomThemes () {
          if (vm.collectiontab.name == 'Precious Memories' && !vm.customThemes) {
            customThemes.getCustomThemes().then(response => {
              vm.customThemes = response.data
            })
          }
        }

        function selectTheme(theme) {
          vm.order.theme_id = theme.id;
          vm.order.theme_id ? setNewOrderTheme() : updateOrder();
          vm.selectedTheme = theme;
          vm.hasSelectedTheme = true;
        }

        function addPin(theme) {
          let alreadyPinned = false;
          let obj = {
            collection_id: vm.collectiontab.id,
            theme_id: theme.id
          };
          vm.pinnedThemes.forEach(pin => {
            if (pin.theme_id == theme.id) {
              alreadyPinned = true;
            }
          });
          if (!alreadyPinned) {
            themes.createPinnedThemes(obj).then(response => {
              let pin = response.data;
              pin.name = theme.name;
              pin.image = theme.image;
              vm.pinnedThemes.push(pin);
            });
            $("#new-pin-alert").modal("show");
          } else {
            $("#already-pinned-alert").modal("show");
          }
        }

        function removePin(pin) {
          themes.deletePinnedThemes(pin.id).then(response => {
            themes.getPinnedThemes(vm.collectiontab.id).then(response => {
              vm.pinnedThemes = response.data;
            });
          });
        }

        function selectPinnedTheme(theme) {
          vm.order.theme_id = theme.theme_id;
          vm.selectedTheme = theme;
          vm.order.theme_id ? setNewOrderTheme() : updateOrder();
        }

        function setNewOrderTheme() {
          vm.orderUpdated = false;
          const id = vm.order.id;
          let order = Object.assign({}, vm.order);
          order.id = undefined;
          order.reset_edited_layouts = true;
          delete order.archived;
          delete order.funeral_home_id;
          delete order.service_start_date
          delete order.service_end_date
          delete order.selected_for_service
          orders.update(id, order).then(response => {
            if (response.status == 200) {
              vm.order = response.data;
              localStorage.setItem("order_id", vm.order.id);
              vm.orderUpdated = true;
            }
          });
        }

        ////  PRINT TAB  ////

        //SELECT and CHECK Products

        async function addCoverPage() {
          const RegBook = vm.orderProducts.filter(item => {
            return item.name === "Register Book" && item.selected == true;
          });
          const RegBookFront = vm.orderProducts.filter(item => {
            return item.name === "Register Book: Front Insert";
          });

          if (RegBook.length > 0 && !RegBookFront.length > 0) {
            let cover_page = vm.products.find(item => {
              return item.name === "Register Book: Front Insert";
            });

            if (cover_page) {
              let newCoverProduct = Object.assign({}, cover_page);
              newCoverProduct.order_id = vm.order.id;
              newCoverProduct.collection_id = vm.collectiontab.id;
              newCoverProduct.product_id = cover_page.id;
              newCoverProduct.selected = true;
              delete newCoverProduct.id;
              delete newCoverProduct.archived;
              await orderProducts.create(newCoverProduct).then(response => {
                vm.orderProducts.push(response.data.product);
              });
            }
          }
        }

        async function filterProducts(extraPages) {
          vm.productLoading = true

          vm.extraPages = vm.orderProducts.filter(item => item.name === "Register Book: Extra Pages" && item.selected === true);
          isFrontSelected();

          await addCoverPage();

          // determines if the product is available with the selected theme
          for (let i in vm.orderProducts) {
            const response = await themes
            .getSelectedThemeLayoutByProduct(
              vm.orderProducts[i].product_id,
              vm.order.theme_id
            )
            
            vm.orderProducts[i].available = false;
            for (let theme_layout of response.data) {
              if (theme_layout.product_id === vm.orderProducts[i].product_id && theme_layout.available) {
                vm.orderProducts[i].available = true;
              }
            }
          }
          
          vm.productLoading = false
          extraPages ? selectProduct(extraPages, vm.collectiontab) : selectFirstProduct()
        }

        function isFrontSelected() {
          // determines if the front insert is selected or not
          if(vm.selectedProduct) {
            let insertIndex = vm.orderProducts.map(item => item.name).indexOf('Register Book: Front Insert');
            if((insertIndex || insertIndex == 0) && insertIndex != -1) {
              if (vm.orderProducts[insertIndex].selected)
              vm.orderProducts[insertIndex].selected ? vm.hasFrontInsert = true : vm.hasFrontInsert = false
            }
          }
        }

        function addFrontInsert() {
          // adds a front insert to the Register Book, and updates the database
          let insertIndex = vm.orderProducts
            .map(item => item.name)
            .indexOf("Register Book: Front Insert");
          insertIndex || insertIndex == 0
            ? (vm.orderProducts[insertIndex].selected = true)
            : null;
          let payload = Object.assign({}, vm.orderProducts[insertIndex]);
          const id = payload.id;
          payload.selected = true;
          delete payload.id;
          delete payload.product_id;
          delete payload.available;
          delete payload.archived;
          orderProducts.update(id, payload);
        }

        function selectFirstProduct() {
          //leave in place for now as we are testing a solution
          //vm.productLoading = true

          vm.isProductSelected = false
          let lastSelectedProduct

          // checks if a product has been previously selected by the user
          if (themes.lastSelectedProduct) {
            const currentProduct = vm.orderProducts.find(item => {
              return themes.lastSelectedProduct.id == item.id
            }) || vm.orderProducts[0].id

            if (currentProduct.available && currentProduct.selected) {
              lastSelectedProduct = currentProduct
            }
          }

          // determines if the product is selected and available with the selected theme
          for (let product of vm.orderProducts) {
            if (product.selected && product.available) {
              vm.isProductSelected = true
              break;
            }
          }

          // display the 'product not available' message if there are no products that are available with a theme
          if (vm.isProductSelected) {
            $('.header-wrapper').css('display', 'block')
            $('.no-theme-wrapper').css('display', 'none')
            for (let product of vm.orderProducts) {
              if (product.selected && product.available) {
                lastSelectedProduct ? selectProduct(lastSelectedProduct, vm.collectiontab) : selectProduct(product, vm.collectiontab);
                break;
              }
            }
          } else {
            $('.header-wrapper').css('display', 'none')
            $('.no-theme-wrapper').css('display', 'block')
          }
        }

        function selectProduct(product, collection, userSelected) {
          //try to prevent duplicate calls to create a page set
          if (vm.productLoading === true) {
            return;
          }
          vm.productLoading = true;
          $("#productBuild").css("visibility", "hidden");
          $(".layoutDrop").css("visibility", "hidden");
          $("#render").css("display", "block");
          vm.selectedSize = {};
          let default_id = "";
          funeralHomeDefaultLayouts.get().then(defaultLayouts => {
            for (let defaultLayout of defaultLayouts.data) {
              if (defaultLayout.product.id == product.product_id) {
                default_id = defaultLayout.layout_id;
              }
            }
            orderLayouts.get(product.id).then(layouts => {
              themes
                .getSelectedThemeLayoutByProduct(
                  product.product_id,
                  vm.order.theme_id
                )
                .then(theme_layouts => {
                  vm.productLayouts = layouts.data;
                  vm.theme_layouts = theme_layouts.data;
                  vm.productLayouts.map(layout => {
                    for (let themelayout of vm.theme_layouts) {
                      if (layout.layout_id == themelayout.layout_id) {
                        if (themelayout.available) {
                          layout.available = true;
                        } else {
                          layout.available = false;
                        }
                      }
                    }
                  });
                  if (vm.productLayouts.length > 1) {
                    vm.selectedSize =
                      vm.productLayouts.find(item => {
                        return default_id == item.layout_id && item.available;
                      }) || {};

                    if (!vm.selectedSize.id) {
                      vm.selectedSize =
                        vm.productLayouts.find(item => {
                          return (
                            localStorage[item.product_id] == item.id &&
                            item.available
                          );
                        }) || {};
                    }

                    if (!vm.selectedSize.id) {
                      vm.selectedSize =
                        vm.productLayouts.find(item => {
                          return item.default && item.available;
                        }) || {};
                    }
                  }

                  if (!vm.selectedSize.id) {
                    vm.selectedSize = vm.productLayouts.find(item => {
                      return item.available;
                    });
                  }

                // checks for any previously selected layouts, if found, use that layout
                if (themes.selectedLayouts[product.id] && !default_id) {
                  const lastSelectedLayout = vm.productLayouts.find(item => {
                    return themes.selectedLayouts[product.id] == item.layout_id
                  })
                  if (lastSelectedLayout) vm.selectedSize = lastSelectedLayout
                }

                if (!vm.selectedSize.id) {
                  vm.selectedSize = vm.productLayouts.find(item => {
                    return item.available
                  })
                }
                getPageSet(vm.selectedSize);
                $(".layoutDrop").css("visibility", "visible");
                getThemeLayout();
              });
            });
          });

          vm.defaultTemp = null;
          vm.selectedProduct = product;
          vm.selectedProductCollection = collection;
          if (userSelected) themes.lastSelectedProduct = product;
        }

        function selectSize(layout, index) {
          themes.selectedLayouts[vm.selectedProduct.id] = layout.layout_id;
          vm.productLoading = true;
          vm.selectedSize = layout;
          getPageSet(vm.selectedSize);
          vm.defaultTemp = null;
          getThemeLayout();
        }

        function getPageSet(layout) {
          vm.orderPageSet = {};
          vm.productPages = [];
          let pageSetResponse = [];
          pages.getOrderPageSets(layout.id).then(response => {
            pageSetResponse = response.data;
            if (pageSetResponse.length < 1) {
              pages.getHomeLayoutPageSets(layout.layout_id).then(response => {
                let pageSet;
                if (response.data.length == 1) {
                  pageSet = response.data[0];
                } else {
                  let defaults = response.data.filter(sets => {
                    return sets.default == true;
                  });
                  if (defaults.length > 1) {
                    defaults.forEach(item => {
                      if (item.home_template && item.default) {
                        pageSet = item;
                      }
                    });
                  } else if (defaults.length == 1) {
                    pageSet = defaults[0];
                  }
                }
                if (!pageSet.id) pageSet = response.data[0];
                let payload = Object.assign({}, pageSet);
                payload.page_set_id = pageSet.id;
                payload.order_layout_id = layout.id;
                delete payload.id;
                delete payload.archived;
                delete payload.funeral_home_id;
                pages.createOrderPageSet(payload).then(response => {
                  vm.orderPageSet = response.data.page_set;
                  vm.productPages = response.data.pages;
                  if (vm.tab == "customize") {
                    buildProduct();
                  }
                });
              });
            } else {
              let defaults = pageSetResponse.filter(pageSet => {
                return pageSet.default == true;
              });
              if (defaults.length > 1) {
                defaults.forEach(item => {
                  if (item.home_template && item.default) {
                    vm.orderPageSet = item;
                  }
                });
              } else {
                vm.orderPageSet = defaults[0];
              }
              if (!vm.orderPageSet || !vm.orderPageSet.id) {
                vm.orderPageSet = pageSetResponse[0];
              }
              getPages(vm.orderPageSet);
            }
          });
        }

        function getPages() {
          pages.getOrderPageDetails(vm.orderPageSet.id).then(response => {
            vm.productPages = response.data;

            if (vm.tab == "customize") {
              buildProduct();
            }
          });
        }

        function removeProduct(product) {
          const id = product.id;
          product.selected = false;
          let payload = Object.assign({}, product);
          delete payload.id;
          delete payload.product_id;
          delete payload.available;
          delete payload.archived;
          vm.products.map(p => {
            if (p.id == product.product_id) {
              p.selected = false;
            }
          });
          orderProducts.update(id, payload).then(response => {
            if (product.id == vm.selectedProduct.id) {
              vm.products.some(p => p.selected)
                ? selectFirstProduct()
                : (vm.tab = "products");
            }
            vm.extraPages = vm.orderProducts.filter(item => {
              return (
                item.name === "Register Book: Extra Pages" &&
                item.selected == true
              );
            });
          });
          if (product.name === "Register Book") {
            const frontInsert = vm.orderProducts.filter(item => {
              return (
                item.name === "Register Book: Front Insert" &&
                item.selected == true
              );
            });
            if (frontInsert.length > 0) removeProduct(frontInsert[0]);
          }
        }

        //  BUILD  Product //
        function getPrintOffset() {
          if (vm.funeralHomeSettings) {
            vm.printSettings.left = vm.funeralHomeSettings.print_parameter_left
              ? parseFloat(vm.funeralHomeSettings.print_parameter_left)
              : 0;
            vm.printSettings.right = vm.funeralHomeSettings
              .print_parameter_right
              ? parseFloat(vm.funeralHomeSettings.print_parameter_right)
              : 0;
            vm.printSettings.bottom = vm.funeralHomeSettings
              .print_parameter_bottom
              ? parseFloat(vm.funeralHomeSettings.print_parameter_bottom)
              : 0;
            vm.printSettings.top = vm.funeralHomeSettings.print_parameter_top
              ? parseFloat(vm.funeralHomeSettings.print_parameter_top)
              : 0;
          } else {
            vm.printSettings = {
              left: 0,
              right: 0,
              bottom: 0,
              top: 0
            };
          }
        }

        function shiftPrintElements (align, odd, even, top) {
          // moves all half sheets to the bottom of the page
          if (vm.selectedSize.name == "Half Sheet") {
            $('#productBuild #page > *').css({
              'left': `${align === 'odd' ? odd : even}px`,
              'top': 'auto',
              'bottom': `${top > 0 ? `-${top}` * 1 : Math.abs(top)}px`,
              'position': 'absolute'
            })
          // applies the offset to products
          } else {
            $(`#productBuild #page:nth-child(${align}) > *`).css({
              'left': `${align === 'odd' ? odd : even}px`,
              'top': `${top}px`,
            })
          }
        }

        function applyPrintOffsets(index) {
          let even, odd
          let {left, right, top, bottom} = vm.printSettings
          const name = vm.selectedProduct.name
          const convertPixelsToInches = (num, isPositive) => isPositive == 'positive' ? num * 72 : `-${num * 72}` * 1

          // reset the left and background properties
          if (name.includes("Square Candle") || name.includes('Small Candle')) $(".printedarea").css('left', '0px')
          $('#productBuild #page').css('background-color', 'transparent')

          
          // converts the inches to pixels, and sets the offset values
          if (right === 0) (even = convertPixelsToInches(left, 'positive'), odd = convertPixelsToInches(left, 'negative')) 
          else (even = convertPixelsToInches(right, 'negative'), odd = convertPixelsToInches(right, 'positive'))
          if (top === 0) (top = convertPixelsToInches(bottom, 'positive'))
          else (top = convertPixelsToInches(top, 'negative'))

          // changes printedarea position from relative to absolute for Register Book flaps
          if (vm.collectiontab.name != 'Precious Memories' && vm.selectedProduct.name === 'Register Book') {
            $(`#productBuild #page .printedarea`).css('position', 'absolute')
          }

          // determine if the page is even or odd and apply the offset
          if (index % 2 == 0) shiftPrintElements('odd', odd, even, top)
          if (index % 2 != 0) shiftPrintElements('even', odd, even, top)

          // corrects the theme size on first load of never before saved register book
          adjustRBThemeSize(index) 
        }

        function adjustRBThemeSize(index) {
          if (vm.selectedProduct.name === "Register Book" && vm.selectedSize.name == "Full Bleed") {
            
            const printedarea = `#productBuild #page:nth-child(${index+1}) .printedarea`
            const printWidth  = $(`${printedarea}`).css('width')
            const printHeight = $(`${printedarea}`).css('height')
            const themeWidth  = $(`${printedarea} .theme`).css('width')
            const themeHeight = $(`${printedarea} .theme`).css('height')
  
            if (printWidth !== themeWidth || printHeight !== themeHeight) {
              $(`${printedarea} .theme`).css('width', printWidth)
              $(`${printedarea} .theme`).css('height', printHeight)
            }

          }
        }

        function buildProduct() {
          setQuillStyles()
          vm.builtProduct = true
          vm.previewLoading = true
          getPrintOffset()
          let build = $('#productBuild')
          let section = $('#buildSection')
          build.html('')
          let sizes

          if (vm.selectedSize) {
            if (!vm.selectedProduct.name.includes("Square Candle")) {
              vm.productPages.sort(function(a, b) {
                return a.page_number > b.page_number
                  ? 1
                  : b.page_number > a.page_number
                  ? -1
                  : 0;
              });
              angular.forEach(vm.productPages, function(page, index) {
                let grid_top = parseFloat(page.grid_start_point_top) * 72;
                let grid_left = parseFloat(page.grid_start_point_left) * 72;
                let page_height = parseFloat(vm.selectedSize.paper_height) * 72;
                let page_width = parseFloat(vm.selectedSize.paper_width) * 72;
                build.append(
                  `<div id="page" style="padding: ${grid_top}px 0 0 ${grid_left}px; height:${page_height}px; width: ${page_width}px; position:relative;"></div>`
                );

                let array = Array.apply(
                  null,
                  Array(page.columns * page.rows)
                ).map(Number.prototype.valueOf, 0);
                angular.forEach(array, function() {
                  page.template = page.template.replace(
                    /<textarea/g,
                    "<textarea elastic"
                  );
                  let el = $compile(page.template)(vm);
                  $(
                    "#productBuild #page:nth-child(" + (index + 1) + ")"
                  ).append(el);
                  applyPrintOffsets(index);
                });
              });
            } else {
              vm.productPages.sort(function(a, b) {
                return a.page_number > b.page_number
                  ? 1
                  : b.page_number > a.page_number
                  ? -1
                  : 0;
              });
              let page = vm.productPages[0];
              let index = 0;
              let grid_top = parseFloat(page.grid_start_point_top) * 72;
              let grid_left = parseFloat(page.grid_start_point_left) * 72;
              let page_height = parseFloat(vm.selectedSize.paper_height) * 72;
              let page_width = parseFloat(vm.selectedSize.paper_width) * 72;
              build.append(
                `<div id="page" style="padding: ${grid_top}px 0 0 ${grid_left}px; height:${page_height}px; width: ${page_width}px; position:relative;"></div>`
              );
              let partOne = vm.productPages[0].template;
              let partTwo = vm.productPages[1].template;
              partOne = partOne.replace(/<textarea/g, "<textarea elastic");
              partTwo = partTwo.replace(/<textarea/g, "<textarea elastic");
              let el = $compile(partOne)(vm);
              let elTwo = $compile(partTwo)(vm);
              $("#productBuild #page").append(el);
              $("#productBuild #page").append(elTwo);

              applyPrintOffsets(index);
            }
          }

          build.append(helpers.fontStyle);
          if (vm.selectedSize && !vm.defaultTemp) {
            if (!vm.selectedSize.saved) {
              if (vm.funeralHomeSettings) {
                if (vm.funeralHomeSettings.default_font) {
                  $("#productBuild p, #productBuild textarea").each(function() {
                    if (
                      $(this)
                        .parent()
                        .hasClass("ql-editor")
                    ) {
                      $(this).addClass(
                        "ql-font-" +
                          vm.funeralHomeSettings.default_font.replace(
                            /( )/g,
                            ""
                          )
                      );
                      $(this)
                        .find("span")
                        .addClass(
                          "ql-font-" +
                            vm.funeralHomeSettings.default_font.replace(
                              /( )/g,
                              ""
                            )
                        );
                      $(this)
                        .find("cite")
                        .addClass(
                          "ql-font-" +
                            vm.funeralHomeSettings.default_font.replace(
                              /( )/g,
                              ""
                            )
                        );
                    } else {
                      $(this).css(
                        "font-family",
                        vm.funeralHomeSettings.default_font
                      );
                    }
                  });
                }
              }
            }
          } else if (
            vm.order.theme_id == vm.selectedTheme.id &&
            vm.selectedTheme.font
          ) {
            $("#productBuild p, #productBuild textarea").each(function() {
              if ($(this).hasClass("default-font") && vm.themeFont) {
                if (
                  $(this)
                    .parent()
                    .hasClass("ql-editor")
                ) {
                  $(this).addClass(
                    "ql-font-" + vm.themeFont.replace(/( )/g, "")
                  );
                  $(this)
                    .find("span")
                    .addClass("ql-font-" + vm.themeFont.replace(/( )/g, ""));
                  $(this)
                    .find("cite")
                    .addClass("ql-font-" + vm.themeFont.replace(/( )/g, ""));
                } else {
                  $(this).css("font-family", vm.themeFont);
                }
              }
            });
          }

          if (vm.selectedTheme.hide_overlay == "true") {
            //are overlays set to be hidden, if so, hide them, if not display them
            $('*[data-type="shape"]:not(".new-shape")').css("display", "none");
          } else {
            $('*[data-type="shape"]:not(".new-shape")').css("display", "");
          }

          $("#productBuild textarea").each(function() {
            if ($(this).inlineStyle("font-family") == undefined) {
              $(this).css("font-family", "Century Gothic");
            }
            $(this).css({
              resize: "none"
            });
          });

          $timeout(function() {
            $("#productBuild textarea").each(function() {
              $(this).css({
                height: parseInt($(this).css("height")) + 4 + "px"
              });
            });
          }, 300);

          if (
            vm.themeLayout &&
            !vm.selectedSize.background_watermark_edited &&
            vm.collectiontab.paper_type != "preprinted" && 
            !vm.selectedProduct.name.includes("16 x 20")
          ) {
            $("#productBuild .theme").css({
              "background-image": `url(${helpers.imgixTheme}${vm.themeLayout
                .replacement_image || vm.selectedTheme.image})`,
              "background-size": vm.themeLayout.background_fill,
              "background-position": `${vm.themeLayout.background_left} ${
                vm.themeLayout.background_top
              }`,
              overflow: "hidden",
              "background-repeat": "no-repeat"
            });
          } else if (
            vm.themeLayout &&
            !vm.selectedSize.background_watermark_edited &&
            vm.collectiontab.paper_type != "plain"
          ) {
            if (!vm.themeLayout.replacement_image) {
              $("#productBuild .theme").css({
                background: "none",
                overflow: "hidden"
              });
            } else {
              $("#productBuild .theme").css({
                "background-image": `url(${helpers.imgixTheme}${
                  vm.themeLayout.replacement_image
                })`,
                "background-size": vm.themeLayout.background_fill,
                "background-position": `${vm.themeLayout.background_left} ${
                  vm.themeLayout.background_top
                }`,
                overflow: "hidden",
                "background-repeat": "no-repeat"
              });
            }
          }

          if (vm.themeLayout && vm.selectedTheme && !vm.selectedSize.saved) {
            if (vm.selectedTheme.font_color) {
              $("#productBuild .default-font-color").each(function() {
                $(this).css("color", vm.selectedTheme.font_color);
              });
            }
          }

          let logoImage;
          if (
            funeralHomeLogo.logos.small ||
            funeralHomeLogo.logos.medium ||
            funeralHomeLogo.logos.large
          ) {
            logoImage =
              funeralHomeLogo.logos.small ||
              funeralHomeLogo.logos.medium ||
              funeralHomeLogo.logos.large;
          } else {
            funeralHomeLogo.getLogos().then(response => {
              const dbLogos = response.data;
              if (dbLogos.small || dbLogos.medium || dbLogos.large) {
                logoImage = dbLogos.small || dbLogos.medium || dbLogos.large;
              }
            });
          }

          $("#productBuild .logoImage").each(function() {
            if (
              !$(this).hasClass("feathered") &&
              !$(this).hasClass("cropped") &&
              !$(this).hasClass("edited")
            ) {
              $(this)
                .css(
                  "background-image",
                  `url(${helpers.imgixLogo}${logoImage}?h=150)`
                )
                .css("background-size", "100% auto")
                .css("background-repeat", "no-repeat");
              $compile($(this))(vm);
              sizePlaceholderImage($(this));
            }
          });

          placeCaseImages();
          showCorrectCaseInfo();
          prepForPrint();

          $timeout(function() {
            if (!vm.selectedSize.background_watermark_edited) {
              $(".silhouette").remove();
              $(".watermark").remove();
              if (vm.watermarks[0]) {
                let mark_one = convertImage.convertWatermark(vm.watermarks[0]);
                let watermarkEl = `<object data="${mark_one}" type="image/png" class="silhouette" style="width:${
                  vm.watermarks[0].width
                }px; position:absolute; top:${vm.watermarks[0].top}px; left:${
                  vm.watermarks[0].left
                }px;"></object>`;

                $("#productBuild #page:first-child .theme").append(watermarkEl);
              }
              if (vm.watermarks[1]) {
                let mark_two = convertImage.convertWatermark(vm.watermarks[1]);
                let secondwatermarkEl = `<object data="${mark_two}" type="image/png" class="silhouette" style="width:${
                  vm.watermarks[1].width
                }px; position:absolute; top:${vm.watermarks[1].top}px; left:${
                  vm.watermarks[1].left
                }px;"></object>`;

                $("#productBuild #page:first-child .theme").append(
                  secondwatermarkEl
                );
              }
              prepForPrint();
            }
          }, 500);
        }

        function sizePlaceholderImage(image) {
          const src = image
            .css("background-image")
            .replace(/url\((['"])?(.*?)\1\)/gi, "$2")
            .split(",")[0];

          let newImage = new Image();
          newImage.src = src;

          $(newImage).load(function() {
            add();
          });

          function add() {
            let width = newImage.width,
              height = newImage.height;

            let ratio = image.width() / width;
            width = image.width();
            height = height * ratio;

            image.css({
              width: width + "px",
              height: height + "px"
            });
          }
        }

        function setQuillStyles() {
          let spacedFontNames = []
          for (let font in helpers.fonts) {
            spacedFontNames.push(helpers.fonts[font].name)
          }
          const fontSize = domHelpers.fontSizes
          const linestyle = domHelpers.lineStyles

          spacedFontNames.map(name => {
            document.styleSheets[0].addRule(`.ql-picker.ql-font .ql-picker-label[data-value='${name.replace(/[' ']/g, '')}']::before,  .ql-picker.ql-font .ql-picker-item[data-value='${name.replace(/[' ']/g, '')}']::before`,
                `content: '${name}' !important;
                font-family: ${name};`),
            document.styleSheets[0].addRule(`.ql-font-${name.replace(/[' ']/g, '')}`,
                `font-family: ${name} !important;`)
          })

          fontSize.map(num => {
            document.styleSheets[0].addRule(`.ql-picker.ql-size .ql-picker-label[data-value='${num}']::before,  .ql-picker.ql-size .ql-picker-item[data-value='${num}']::before`,
              `content: '${num}' !important;`)
          })

          linestyle.map(num => {
            document.styleSheets[0].addRule(`.ql-picker.ql-line .ql-picker-label[data-value='${num}']::before,  .ql-picker.ql-line .ql-picker-item[data-value='${num}']::before`,
                `content: '${num}' !important;`)
          })

          linestyle.map(num => {
            const dashNum = num.toString().replace('.', '-')
            document.styleSheets[0].addRule(`.ql-line-height-${dashNum}em`, `line-height: ${num} !important`)
          })
        }

        //  GET LAYOUT THEME //

        function getThemeLayout() {
          vm.watermarks = [];
          themes
            .getThemeLayout(vm.selectedSize.layout_id, vm.order.theme_id)
            .then(response => {
              vm.themeLayout = response.data[0];
              if (!vm.selectedSize.background_watermark_edited) {
                if (vm.themeLayout.first_watermark)
                  getWatermarks(vm.themeLayout.first_watermark);
                if (vm.themeLayout.second_watermark)
                  getWatermarks(vm.themeLayout.second_watermark);
              }
            });
        }

        function getWatermarks(id) {
          themes.getWatermarks(id).then(response => {
            vm.watermarks.push(response.data);
          });
        }

        //  PRINT-PDF Product  //
        function prepForPrint() {
          const isLarge = vm.selectedProduct.name.includes('Memorial Collage')
          $timeout(function() {
            $(".printedarea").css("margin-bottom", "0px");
            $("#productBuild .outlines").css({
              width: "100%",
              height: "100%"
            });
            $("#productBuild textarea").each(function() {
              if (!$(this).val()) {
                $(this).remove();
              }
            });
            $("#productBuild").css("position", "relative");
            $("div #page").each(function() {
              $(this).css("overflow", "hidden");
            });

            $("#productBuild .ql-tooltip").each(function() {
              $(this).remove();
            });
            $("#productBuild .ql-clipboard").each(function() {
              $(this).remove();
            });
            $("#productBuild textarea, #productBuild p").each(function() {
              if ($(this).attr("contenteditable")) {
                $(this).attr("contenteditable", false);
              }
              if ($(this).inlineStyle("color") == undefined) {
                $(this).css("color", "rgb(0,0,0)");
              }
              if ($(this).children().is('span') && $(this).children().text() === '') {
                $(this).children().replaceWith('<br>')
              }

              if ($(this).inlineStyle("font-family") == undefined) {
                $(this).css("font-family", "Century Gothic");
              }

              $(this).css({
                resize: "none"
              });
            });
            $("#productBuild .image").each(function() {
              convertImage.convertOpacity(this, isLarge);
            });
            $("#productBuild .versePlaceholder").each(function() {
              if ($(this).inlineStyle("background") == undefined) {
                $(this).remove();
              }
              convertImage.convertOpacity(this, isLarge);
            });
            $("#productBuild .placeholderImage").each(function() {
              if ($(this).inlineStyle("background") == undefined) {
                $(this).remove();
              }
              convertImage.convertOpacity(this, isLarge);
            });
            $("#productBuild .caseImage").each(function() {
              if ($(this)[0].style.border === "5px solid black")
                if ($(this).inlineStyle("background") == undefined) {
                  $(this).remove();
                }
              if (!$(this).hasClass("image")) convertImage.convertOpacity(this, isLarge);
              if ($(this).is("img")) $(this).css("max-width", "100%");
            });
            $("#productBuild .logoImage").each(function() {
              convertImage.convertOpacity(this, isLarge);
            });
            $("#productBuild .theme").each(function() {
              convertImage.convertOpacity(this, isLarge);
              $(this).css("display", "");
            });
            replaceStrong();
          }, 200);
        }

        function handleBulletPoints () {
          let bulletCounter = 1

          // find all list items, and create bullets for them
          $('#page li').each(function () {
            const bullet = $compile(`<b style="position: relative;">• </b>`)(vm)
            const number = $compile(`<b style="position: relative;">${bulletCounter}. </b>`)(vm)
            $(this).css('padding-left', '3em')
            
            // determine if parent is a ul element, then give it • bullets
            if($(this).parent().is('ul')) {
              $(bullet).prependTo(this);
              const bulletWidth = bullet.width()
              bullet.css('margin-left', `-${bulletWidth}px`)
            }

            // determine if parent is a ol element, then give it numbered bullets
            if($(this).parent().is('ol')) {
              $(number).prependTo(this);
              const numberWidth = number.width()
              number.css('margin-left', `-${numberWidth}px`)
              bulletCounter++
            }
          })
        }

        function printPrompt() {
          vm.formatModalMessage = null
          vm.selectedProduct.name.includes("Directory") && vm.selectedSize.name.includes('Full')
            ? $("#format-modal").modal("show")
            : printProduct()
        }

        function printProduct(kind) {
          //replace textareas for paragraphs to allow text-align justify
          $("#page textarea").each(function() {
            let textarea = $(this).prop("outerHTML");
            let paragraph = textarea.replace(/textarea/g, "p");
            paragraph = paragraph.replace(/\r\n|\r|\n/g, "<br />");
            $(this).replaceWith(paragraph);
          });

          hideElements(false)

          $("#page p, #page span, #page cite").each(function() {
            if ($(this).css("font-family") == '"Century Gothic"') {
              $(this).css({
                "-webkit-transform": "scale(0.97)",
                "-moz-transform": "scale(0.97)",
                "-ms-transform": "scale(0.97)",
                transform: "scale(0.97)"
              });
            }
          });
          //during testing leaving these visible but in Production must remove them completely
          $('.photo-outline').each(function() {
            $(this).css({
              'opacity': '0'
            })
          })

          $(".ql-editor").each(function() {
            $(this)
              .find("*")
              .each(function() {
                if ($(this).hasClass("ql-line-height-1em")) {
                  $(this).removeClass("ql-line-height-1em");
                  $(this).css("line-height", "1em");
                }
                if ($(this).hasClass("ql-line-height-1-5em")) {
                  $(this).removeClass("ql-line-height-1-5em");
                  $(this).css("line-height", "1.5em");
                }
                if ($(this).hasClass("ql-line-height-2em")) {
                  $(this).removeClass("ql-line-height-2em");
                  $(this).css("line-height", "2em");
                }
                if ($(this).hasClass("ql-line-height-2-5em")) {
                  $(this).removeClass("ql-line-height-2-5em");
                  $(this).css("line-height", "2.5em");
                }
                if ($(this).hasClass("ql-line-height-0-5em")) {
                  $(this).removeClass("ql-line-height-0-5em");
                  $(this).css("line-height", ".5em");
                }
                if ($(this).hasClass("ql-line-height-3em")) {
                  $(this).removeClass("ql-line-height-3em");
                  $(this).css("line-height", "3em");
                }
                if ($(this).hasClass("ql-line-height-4em")) {
                  $(this).removeClass("ql-line-height-4em");
                  $(this).css("line-height", "4em");
                }
                if ($(this).hasClass("ql-line-height-0-8em")) {
                  $(this).removeClass("ql-line-height-0-8em");
                  $(this).css("line-height", ".8em");
                }
                if ($(this).hasClass("ql-line-height-1-2em")) {
                  $(this).removeClass("ql-line-height-1-2em");
                  $(this).css("line-height", "1.2em");
                }
              });
            $(this)
              .find("span")
              .each(function() {
                let parent = $(this).parent();
                let size = Math.ceil(parseInt($(this).css("font-size")));
                if (size > 16) {
                  size += "px";
                  $(parent).css("font-size", size);
                }
              });
          });
          $(".ql-container").each(function() {
            const styles = copyCSS($(this));
            $(this).css(styles);
            $(this).css('inset', '')
            $(this).css('left', styles.left)
            $(this).css('top', styles.top)
            $(this).removeClass();
            $(this).css("overflow", "visible");
          });
          $(".ql-editor")
            .find("p")
            .each(function() {
              const styles = copyCSS($(this));
              $(this).css(styles);
              $(this).removeClass();
              $(this).css("overflow", "visible");
            });
          $(".ql-editor").each(function() {
            const styles = copyCSS($(this));
            $(this).css(styles);
            $(this).removeClass();
            $(this)
              .find("*")
              .each(function() {
                const isJustified = $(this).css('text-align') === 'justify'
                $(this).css("white-space", isJustified ? "pre-line" : "pre-wrap")
              });
          });

          handleBulletPoints()

          if (vm.order.themepreprinted || vm.selectedProduct.name.includes("16 x 20")) {
            $("#productBuild .theme").css({
              visibility: "hidden"
            });
          }

          prepareCodeForPrint($("#productBuild").html())
            .then(product => generatePrintableFromServer(product, true, kind = kind));

          hideElements(true)
        }

        function prepareCodeForPrint(html) {
          return new Promise((resolve, reject) => {
            let build =  helpers.printHeader + html + helpers.printStyle + helpers.printFooter;

            build = build.replace(/elastic/g, "");

            let product = {
              html: build,
              page_height: parseFloat(vm.selectedSize.paper_height),
              page_width: parseFloat(vm.selectedSize.paper_width)
            };
            product.html = product.html.replace(
              /transform:/g,
              "-webkit-transform:"
            );
            resolve(product);
          })
        }

        function hideElements(show) {
          $('.visible-area').css('visibility', show ? 'visible' : 'hidden')
        }

        function generatePrintableFromServer(product, isLastPdf, kind = 'pdf') {
          vm.loadingPrint = true
          vm.formatModalMessage = 'Generating print...'
          if(kind === 'jpeg') {
            orders.generateJPEG({ html:product.html })
              .then(response => {
                if(response.data) {
                  var link = document.createElement("a");
                  link.download = name;
                  link.href = response.data;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }
                $("#format-modal").modal("hide")
                $timeout(() => {
                  vm.loadingPrint = false
                  vm.formatModalMessage = null
                }, 100)
              })
              .catch(err => {
                vm.loadingPrint = false
                vm.formatModalMessage = err.data && err.data.message
                  ? err.data.message
                  : err.data
                vm.$apply()
              })
          } else {
            orders.generatePDF(product).then(response => {
              if (isLastPdf) {
                $("#page p").each(function () {
                  if ($(this).css("font-family") == '"Century Gothic"') {
                    $(this).css({
                      "-webkit-transform": "scale(1)",
                      "-moz-transform": "scale(1)",
                      "-ms-transform": "scale(1)",
                      transform: "scale(1)"
                    });
                  }
                });
                $('.photo-outline').each(function() {
                  $(this).css({
                    'opacity': '1'
                  })
                })
              }
              if(response.data){
                let file = new Blob([response.data], {
                  type: "application/pdf"
                });
                let fileURL;
                if (window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(file, "product.pdf");
                } else {
                  fileURL = URL.createObjectURL(file);
                  let openWindow = window.open(fileURL);
                  try {
                    openWindow.focus();
                  } catch (err) {
                    $("#popup-modal").modal("show");
                  }
                }
                if (vm.order.themepreprinted || vm.selectedProduct.name.includes("16 x 20")) {
                  $("#productBuild .theme").css({
                    visibility: "visible"
                  });
                }
              } else {
                alert(`The PDF you are trying to generate is too large. For help, please contact support@bass-mollett.com`)
              }
              $("#format-modal").modal("hide")
              vm.loadingPrint = false;
            });
          }
        }

        //  CHECK DATA  //
        function hasPlainProducts() {
          let hasPlain = false;
          if (vm.collectiontab.name == "Precious Memories") hasPlain = true;
          return hasPlain;
        }

        function hasPreprintedProducts() {
          let hasPreprinted = false;
          if (vm.collectiontab.name != "Precious Memories")
            hasPreprinted = true;
          return hasPreprinted;
        }

        function hasSelectedProducts(collection) {
          let hasSelected = false;
          angular.forEach(vm.products, function(product) {
            if (product.selected) hasSelected = true;
          });
          return hasSelected;
        }

        function orderHasTheme() {
          if (vm.order.theme_id) return true;
          return false;
        }

        function orderHasProduct() {
          if (!vm.order.collection_id) {
            getCollections();
            return false;
          } else {
            getThemeCategories();
            return vm.products.some(product => product.selected);
          }
        }

        function deactivateOtherCollections(collection) {
          let hasActive = false;
          angular.forEach(collection.products, function(product) {
            if (product.selected) hasActive = true;
          });
          if (hasActive) {
            vm.availableCollection = collection.name;
          } else {
            vm.availableCollection = null;
          }
        }

        function getFuneralHomes() {
          let defered = $q.defer();
          funeralHomes.get().then(response => {
            vm.home = response.data;
            if (!vm.home.settings) {
              vm.home.settings = {};
            }
            defered.resolve(true);
          });
          return defered.promise;
        }

        function selectVerse(verse) {
          vm.order.verse = verse;
          vm.order.verseId = verse._id;
        }

        function getVerses() {
          assetLibraries.getAll().then(response => {
            let libraries = response.data;
            let verses = [];
            let i = 0;

            function iterate() {
              if (i < libraries.length) {
                if (libraries[i].name.indexOf("Verses") >= 0) {
                  assets.getLibraryAssets(libraries[i]._id).then(response => {
                    for (let j in response.data) {
                      verses.push(response.data[j]);
                    }
                    let groups = _.groupBy(response.data, "subcategory");
                    libraries[i].assets = groups;
                    i++;
                    iterate();
                  });
                } else {
                  i++;
                  iterate();
                }
              } else {
                vm.verses = verses;
              }
            }
            iterate();
          });
        }

        const caseUpdateEventListener = $rootScope.$on(
          "caseUpdated",
          (event, payload) => {
            if (!vm.showOneCase) {
              getActiveCases();
            } else {
              vm.availableCases = [];
              vm.availableCases.push(payload);
              assignCase(payload)
            }
            if ($rootScope.duplicateOrder) {
              buildProduct();
              delete $rootScope.duplicateOrder;
            }
          }
        );

        scope.$on("$destroy", () => caseUpdateEventListener());

        function rgb2hex(rgb) {
          rgb = rgb.match(
            /^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i
          );
          return rgb && rgb.length === 4
            ? "#" +
                ("0" + parseInt(rgb[1], 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[2], 10).toString(16)).slice(-2) +
                ("0" + parseInt(rgb[3], 10).toString(16)).slice(-2)
            : "";
        }

        function layoutAlert() {
          !localStorage.layoutAlert &&
          vm.orderPageSet &&
          vm.productLayouts.length > 1 &&
          vm.selectedSize.name == "Full Bleed"
            ? $("#layout-alert").modal("show")
            : $location.path(`editor/${vm.order.id}/${vm.orderPageSet.id}`);
        }

        function layoutConfirm() {
          $("#layout-alert").modal("hide");
          $timeout(function() {
            $location.path(`editor/${vm.order.id}/${vm.orderPageSet.id}`);
          }, 300);
        }

        function layoutAlertDismiss() {
          localStorage.layoutAlert = true;
        }

        function rememberSelectedSize(index) {
          localStorage[vm.selectedProduct._id] = index;
        }

        function showSlider() {
          setTimeout(function() {
            vm.showSlider = true;
          }, 1000);
        }

        function setSelectedCase(idSelectedCase) {
          vm.idSelectedCase = idSelectedCase;
        }

        function calculateAge(){
          if(!vm.case.date_of_birth){
            vm.case.date_of_birth = null
          }
          if(!vm.case.date_of_death){
            vm.case.date_of_death = null
          }
          if(vm.case.date_of_birth && vm.case.date_of_death){
            var birth = moment.utc(vm.case.date_of_birth)
            var death = moment.utc(vm.case.date_of_death)

            var years = death.diff(birth, 'years')
            vm.case.age = death.diff(birth, 'years')
            vm.case.age_years = death.diff(birth, 'years')

            death = death.subtract(vm.case.age_years, 'years')
            vm.case.age_months = death.diff(birth, 'months')
            death = death.subtract(vm.case.age_months, 'months')
            vm.case.age_days = death.diff(birth, 'days')
          }
        }

        function setCaseImageHeight(image) {

          if(vm.case.personal_images && vm.case.personal_images.length) {

            //get updated url
            let width, height, newWidth, newHeight, path, newImage

            const caseImageBackground = image.css('background-image')

            if (caseImageBackground.includes('url')) {

              path = caseImageBackground.split('"')[1]
              path = path.split('&&').join('&')
            
              // get crop's width and height
              newWidth  = parseInt($('#edit_image').css('width'))
              newHeight = parseInt($('#edit_image').css('height'))
            
              //if too large for page, make smaller
              if(newWidth > 250) {
                width  = '250px'
                height = 250 * (newHeight/newWidth) + 'px'
              } else {
                width  = newWidth,
                height = newHeight
              }
            
              //set selected element's url to the edited query
              newImage = new Image();
              newImage.src =  path
              
              newImage.onload = function() {
            
                image.css({
                  'width'  : parseInt(image.css('width')),
                  'height' : parseInt(image.css('width')) * (newImage.height/newImage.width) + 'px'
                })
            
                if(image.hasClass('cropped') && !image.hasClass('edited')) {
            
                  image.css({
                    width  : width,
                    height : height,
                  })
            
                }
            
              }

            }

          }

				}

        function placeCaseImages() {

          const caseImagesBefore  = []
          const caseImages        = sortCaseImages()
          const isMemorialCollage = vm.selectedProduct.name.includes('Memorial Collage')

          if (vm.case && caseImages) {

            $("#productBuild .caseImage").each(function () {
              if($(this)[0].hasAttribute("data-caseimage")) {

                const data = {
                  element            : $(this),
                  caseImages         : caseImages,
                  num                : $(this).data("caseimage"),
                  isOval             : $(this).data("oval"),
                  isEdited           : $(this).hasClass("edited"),
                  isCropped          : $(this).hasClass("cropped"),
                  isFeathered        : $(this).hasClass("feathered"),
                  isDefaultCaseImage : $(this).data().caseimage === 0
                }

                const { element, num } = data

                // store all case image background-images in case of edits
							caseImagesBefore.push({
								backgroundImage   : element.css('background-image'),
								caseImagePosition : num
							})

                updateDefaultImage(data)
                !isMemorialCollage ? setCaseImages(data) : setMemorialCollageCaseImages(data)

              }
            });

            $('#productBuild .caseImage').each(function() {

              setCaseImageHeight($(this))

              if($(this)[0].hasAttribute("data-caseimage") && isMemorialCollage) {

                const currentBgImage = $(this).css('background-image')
                const currentBgUrl   = currentBgImage.split('?')[0].split(`${helpers.imgixCase}`)[1]

                caseImagesBefore.map(image => {

                  // restore any case image edits
                  const previousBgUrl = image.backgroundImage.split('?')[0].split(`${helpers.imgixCase}`)[1]
                  currentBgUrl === previousBgUrl && $(this).css('background-image', image.backgroundImage)

                  // change previous default image circle feathers to square feathers
                  if (previousBgUrl === currentBgUrl && image.caseImagePosition === 0 && $(this).data('caseimage') !== 0) {
                    $(this).css('background-image', image.backgroundImage.replace('circlefeather.png', 'squarefeatherbox.png'))
                  }

                })

                // change new default image square feathers to circle feathers
                if ($(this).css('background-image').includes('squarefeatherbox.png') && $(this).data('caseimage') === 0) {
                  $(this).css('background-image', currentBgImage.replace('squarefeatherbox.png', 'circlefeather.png'))
                }

              }
						})

          }

        }

        function sortCaseImages() {
          
          let caseImages = [];
          const totalCaseImages = vm.case.personal_images && vm.case.personal_images.length

          // if there are less than twenty case images, add the same case images over and over until the caseImages array has 20 images
          if (vm.case.personal_images && totalCaseImages < 20) {
            let count = 0;
            while (count < 20) {
              const imagesString = vm.case.personal_images.toString();
              const imagesSplit = imagesString.split(",");
              count += imagesSplit.length;
              caseImages.push(imagesSplit);
            }
            caseImages = caseImages.reduce((a,b) => a.concat(b), []);

          // if there is twenty or more images, simply add them to the caseImages array
          } else if (totalCaseImages >= 20) {
            caseImages = vm.case.personal_images;
          }

          return caseImages

        }

        function updateDefaultImage(data) {

          const { element, caseImages } = data

          // if the default image has been changed and it has been edited, cropped, or feathered, then replace the default image
          if (element[0].attributes['data-caseimage'].value === '0') {

            let baseImage  = element.css('background-image').split('?')[0].split('/')[3]
            let imgUpdated = localStorage.getItem('defaultImgUpdated')

            if (imgUpdated) {
              element.removeClass('edited')
              element.removeClass('cropped')
              element.removeClass('feathered')
              element.css({
                'background-image' : `url(${helpers.imgixCase}${caseImages[0]}?or=0)`,
                'background-size'  : 'cover'
              })
              sizePlaceholderImage(element)
            }

          }
        }

        function setCaseImages(data) {

          const { caseImages, element, num, isEdited, isCropped, isFeathered } = data

          if (!isFeathered && !isCropped && !isEdited) {
                  
            if (caseImages[num]) {
              element.css({
                "background-image" : `url(${helpers.imgixCase}${caseImages[num]}?or=0&fm=png)`,
                "background-size"  : "cover" 
              })
              !vm.selectedSize.saved && sizePlaceholderImage(element);
            } else {
              element.css({
                "display"          : "none",
                "background-image" : "none"
              })
            }
          }

        }

        function setMemorialCollageCaseImages(data) {

          const { element, num, isOval } = data
          const personalImage = vm.case.personal_images && vm.case.personal_images[num]

          const maskType         = (num === 0 || isOval) ? 'circlefeather.png' : 'squarefeatherbox.png'

          const featherFlags     = `?&fm=png&border=0,000000&pad=0&fm=png&auto=compress&fit=crop&mask=${helpers.imgixBase}${maskType}`
          const caseImageFillers = {
            0  : `${helpers.imgixCase}case_images_440x655.png`,
            1  : `${helpers.imgixCase}case_images_345x505.png`,
            2  : `${helpers.imgixCase}case_images_345x450.png`,
            3  : `${helpers.imgixCase}case_images_345x515.png`,
            4  : `${helpers.imgixCase}case_images_340x490.png`,
            5  : `${helpers.imgixCase}case_images_345x575.png`,
            6  : `${helpers.imgixCase}case_images_340x515.png`,
            7  : `${helpers.imgixCase}case_images_615x405.png`,
            8  : `${helpers.imgixCase}case_images_345x430.png`,
            9  : `${helpers.imgixCase}case_images_465x410.png`,
            10 : `${helpers.imgixCase}case_images_370x430.png`
          }

          const replaceImage = url => {
            element.css({
              'background-image' : url,
              'background-size'  : 'cover'
            })
            dom.sizePlaceholderImage(element)
          }

          num === 0 && $(element).css('z-index', '50')

          personalImage
            ? replaceImage(`url("${helpers.imgixCase}${personalImage}${featherFlags}")`)
            : replaceImage(`url("${caseImageFillers[num]}${featherFlags}")`)

        }

        function showCorrectCaseInfo() {
          calculateAge()
          if(!funeralHomeSettings.settings) {
            funeralHomeSettings.get().then(() => {})
          }
          const ser = vm.case.services ? vm.case.services.filter(item => item.default)[0] : null;
          const service = ser === undefined ? vm.case.services[0] : ser;

          $("#productBuild textarea, #productBuild p, #productBuild cite").each(
            function() {
              let caseinfo = $(this).data("label");
              const dateFormat = funeralHomeSettings.settings && funeralHomeSettings.settings.date_format ? funeralHomeSettings.settings.date_format : 'LL'
              if (caseinfo) {
                
                if (caseinfo === "case.service_time") {

                  let text = (service && service.time) ? `${service.time}` : ''
                  $(this).html(`${text}`);
                } else if (caseinfo === "case.service_location") {

                  let text = (service && service.location) ? `${service.location}${service.city ? ', ' + service.city: ''}${service.state ? ', ' + service.state: ''}` : ''
                  $(this).html(`${text}`);

                } else if (caseinfo.includes("visitations") && caseinfo.includes("date")) {

                  const currentVisitation = caseinfo.split('[')[1].split(']')[0] * 1
                  const visitation = vm.case.visitations[currentVisitation]
                  let text = (visitation && visitation.date) ? moment.utc(visitation.date).format(dateFormat) : ''
                  text === 'Invalid date' && (text = '')
                  $(this).html(text);

                } else if (caseinfo.includes("services") && caseinfo.includes("date") && vm.case.services) {

                  const currentService = caseinfo.split('[')[1].split(']')[0] * 1
                  const service = vm.case.services[currentService]
                  let text = (service && service.date) ? moment.utc(service.date).format(dateFormat) : ''
                  text === 'Invalid date' && (text = '')
                  $(this).html(text);

                } else if (caseinfo.includes("justServices") && caseinfo.includes("date") && vm.case.justServices) {

                  const currentJustService = caseinfo.split('[')[1].split(']')[0] * 1
                  const justService = vm.case.justServices[currentJustService]
                  let text = (justService && justService.date) ? moment.utc(justService.date).format(dateFormat) : ''
                  text === 'Invalid date' && (text = '')
                  $(this).html(text);
                  
                } else if (caseinfo.includes("case.service_date")) {

                  let text = (service && service.date) ? moment.utc(service.date).format(dateFormat) : ''
                  text === 'Invalid date' && (text = '')
                  $(this).html(text);

                } else if (caseinfo.includes('.date') || caseinfo.includes('_date') || caseinfo.includes('date_')) {

                  const currentCase = caseinfo.split('.')[1].split(' ')[0]
                  let text = `${moment.utc(vm.case[currentCase]).format(dateFormat)}`
                  text === 'Invalid date' && (text = '')
                  $(this).html(`${text}`)

                } else if (caseinfo === "case.clergy_name") {
                  let text =  (vm.case.clergy && vm.case.clergy.length > 0 && `${vm.case.clergy[0]}` != 'null')  ? `${vm.case.clergy[0]}` : ''
                  
                  $(this).html(text);
                } else {
                  let text = vm.$eval(caseinfo);
                  $(this).html(text);
                }

                if (caseinfo.includes('.city')) {
                  let text = vm.$eval(caseinfo);
                  $(this).html(`${text}, `);
                }

              }

              if ($(this)[0].hasAttribute("data-casetitle")) {
                let datafield = "helpers." + $(this).data("casetitle");

                let titleField = datafield.replace(".title", ".handlebar");
                let title = eval(titleField)
                  .replace("case.", "")
                  .replace(" | date: 'longDate'", '')
                  .replace(" | date: 'MMMM d, yyyy'", "");
                let test1 = eval(datafield);
                let parent = $(this).parent();
                let par = $(this).text(test1);
                $(this).remove();
                let newPar = $compile(par)(vm)[0];

                $(parent).append(newPar);
                for (let field in funeralHomeSettings.settings) {
                  if (!funeralHomeSettings.settings.hasOwnProperty(field))
                    continue;
                  if (!funeralHomeSettings.settings[field]) continue;
                  if (field.indexOf("case") != -1) {
                    if (field.indexOf(title) != -1) {
                      let newTitle = funeralHomeSettings.settings[field];
                      let newParent = $(this).parent();
                      let par2 = $(this).text(newTitle);
                      $(this).remove();
                      let newPar2 = $compile(par2)(vm)[0];
                      $(newParent).append(newPar2);
                    }
                  }
                }
              }
            }
          );

            ////LEAVE in PLACE FOR FUTURE EVEN THOUGH THEY NO WANT
          // $('#productBuild .printedarea p.ql').each(function(index){
          //   let parent, field, data
          //   $(this).find('cite[data-multi="true"]').each(function(index) {
          //     parent = $(this).parent()
          //     const compare1 = data ? data.substring(0, data.indexOf('[')) : null
          //     const compare2 = $(this).attr('data-caseinfo').substring(0, $(this).attr('data-caseinfo').indexOf('['))
          //     if(data && compare1 === compare2) {
          //       //do nothing -> continue to remove(); below
          //     } else {
          //       data = $(this).attr('data-caseinfo')

          //       //get rid of 'data-caseinfo=' case.field and ...

          //       if(data) field = `${data.substring(5, data.indexOf('['))}`
          //       const style = $(this).attr('data-columns') == 'true' ? "width: 50%; display: inline-block; margin: 0;" : "width: 100%; display:inline-block; margin:0;" 
          //       const columnClass = $(this).attr('data-columns') == 'true' ? "columns" : "" 
          //       const columns = $(this).attr('data-columns') == 'true' ? {data: 'data-columns', value: 'true'} : {};

          //         if(field) {
          //           for (let n in vm.case[field]) {
                      
          //             const caseField = dom.HtmlElement.create('cite')
          //               .addClasses([
          //                 'ql-data-content',
          //                 columnClass
          //               ])
          //               .addAttrs([
          //                 {data: 'data-label', value: `case.${field}[${n}]`,},
          //                 {data: 'data-caseinfo', value: `case.${field}[${n}]`,},
          //                 {data: 'data-id', value: `case.${field}[${n}]`,},
          //                 {data: 'data-multi', value: 'true'},
          //                 columns,
          //                 {data: 'style',value: style},
          //               ])
          //               .addTextContent(vm.case[field][n])
          //               .appendTo(parent[0])
          //           }
          //         }
          //       }
          //     $(this).remove()
          //   })
          // });
          // $('#productBuild .printedarea p.ql').each(function(index) {
          //   let parent2, field2, data2
          //   $(this).find('cite[data-multi="object"]').each(function(index) {
          //     parent2 = $(this).parent()
          //     const compare1 = data2 ? data2.substring(0, data2.indexOf('[')) : null
          //     const compare2 = $(this).attr('data-caseinfo').substring(0, $(this).attr('data-caseinfo').indexOf('['))
          //     if(data2 && compare1 === compare2) {
          //       //do nothing -> continue to remove(); below
          //     } else {
          //       data2 = $(this).attr('data-caseinfo')

          //       if(data2) field2 = `${data2.substring(5, data2.indexOf('['))}`
              
          //       const style = $(this).attr('data-columns') == 'true' ? "width: 50%; display: inline-block; margin: 0;" : "width: 100%; display:inline-block; margin:0;" 
              
          //         if(field2) {
          //           for (let k in vm.case[field2]) {
          //             const caseField1 = dom.HtmlElement.create('cite')
          //               .addClasses([
          //                 'ql-data-content',
          //               ])
          //               .addAttrs([
          //                 {data: 'data-label',value: `case.${field2}[${k}].location`},
          //                 {data: 'data-caseinfo',value: `case.${field2}[${k}].location`},
          //                 {data: 'data-id',value: `case.${field2}[${k}].location`},
          //                 {data: 'data-multi',value: 'object'},
          //                 {data: 'style',value: style},
          //               ])
          //               .addTextContent(vm.case[field2][k].location)
          //               .appendTo(parent2[0])
                        
          //             const caseField2 = dom.HtmlElement.create('cite')
          //               .addClasses([
          //                 'ql-data-content',
          //               ])
          //               .addAttrs([
          //                 {data: 'data-label',value: `case.${field2}[${k}]date`},
          //                 {data: 'data-caseinfo',value: `case.${field2}[${k}]date`},
          //                 {data: 'data-id',value: `case.${field2}[${k}]date`},
          //                 {data: 'data-multi',value: 'object'},
          //                 {data: 'style',value: style},
          //               ])
          //               .addTextContent(vm.case[field2][k].date)
          //               .appendTo(parent2[0])
                        
          //             const caseField3 = dom.HtmlElement.create('cite')
          //               .addClasses([
          //                 'ql-data-content',
          //               ])
          //               .addAttrs([
          //                 {data: 'data-label',value: `case.${field2}[${k}].time`},
          //                 {data: 'data-caseinfo',value: `case.${field2}[${k}].time`},
          //                 {data: 'data-id',value: `case.${field2}[${k}].time`},
          //                 {data: 'data-multi',value: 'object'},
          //                 {data: 'style',value: style},
          //               ])
          //               .addTextContent(vm.case[field2][k].time)
          //               .appendTo(parent2[0])
          //           }
          //         }
          //       }
          //     $(this).remove()
          //   })
          // })

          $("#productBuild textarea, #productBuild p ").each(function() {
            let caseinfo = $(this).data("caseinfo");
            let caseLabel = $(this).data("label");
            const dateFormat = funeralHomeSettings.settings && funeralHomeSettings.settings.date_format ? funeralHomeSettings.settings.date_format : 'LL'
            if (caseinfo) {
              if (caseinfo == "case.children") {
                var children = "";

                for (var j in vm.case.children) {
                  if (vm.case.children[j]) {
                    children += vm.case.children[j] + " \n";
                  }
                }
                $(this).html(children);
              } else if (caseinfo == "case.pallbearers") {
                var pallbearers = "";
                for (var j in vm.case.pallbearers) {
                  if (vm.case.pallbearers[j]) {
                    pallbearers += vm.case.pallbearers[j] + " \n";
                  }
                }
                $(this).html(pallbearers);
              } else if (caseinfo == "case.honorary_pallbearers") {
                var honorary_pallbearers = "";
                for (var j in vm.case.honorary_pallbearers) {
                  if (vm.case.honorary_pallbearers[j]) {
                    honorary_pallbearers += vm.case.honorary_pallbearers[j] + " \n";
                  }
                }
                $(this).html(honorary_pallbearers);
              } else if (caseinfo == "case.societies") {
                var societys = "";
                for (var j in vm.case.societies) {
                  if (vm.case.societies[j]) {
                    societys += vm.case.societies[j] + " \n";
                  }
                }
                $(this).html(societys);
              } else if (caseinfo == "case.organizations") {
                var organizations = "";
                for (var j in vm.case.organizations) {
                  if (vm.case.organizations[j]) {
                    organizations += vm.case.organizations[j] + " \n";
                  }
                }
                $(this).html(organizations);
              } else if (caseinfo == "case.songs") {
                var songs = "";
                for (var j in vm.case.songs) {
                  if (vm.case.songs[j]) {
                    songs += vm.case.songs[j] + " \n";
                  }
                }
                $(this).html(songs);
              } else if (caseinfo == "case.special_songs") {
                var special_songs = "";
                for (var j in vm.case.special_songs) {
                  if (vm.case.special_songs[j]) {
                    special_songs += vm.case.special_songs[j] + " \n";
                  }
                }
                $(this).html(special_songs);
              } else if (
                caseinfo.includes("case.service_date") ||
                (caseinfo.includes("services") && caseinfo.includes("date")) ||
                (caseinfo.includes("justServices") && caseinfo.includes("date"))
              ) {
                let text = (service && service.date) ? moment.utc(service.date).format(dateFormat) : ''
                text === 'Invalid date' && (text = '')
                $(this).html(text);
              } else if (caseinfo.includes("visitations") && caseinfo.includes("date")) {
                let text = (visitation && visitation.date) ? moment.utc(visitation.date).format(dateFormat) : ''
                text === 'Invalid date' && (text = '')
                $(this).html(text);
              } else if (caseinfo.includes('.date') || caseinfo.includes('_date') || caseinfo.includes('date_')) {
                const currentCase = caseinfo.split('.')[1].split(' ')[0]
                let text = `${moment.utc(vm.case[currentCase]).format(dateFormat)}`
                text === 'Invalid date' && (text = '')
                $(this).html(`${text}`)
              } else if (caseinfo == "case.service_time") {
                let text = (service && service.time) ? `${service.time}`: '';
                $(this).html(text);
              } else if (caseinfo == "case.service_location") {
                let text = (service &&  service.location) ? `${service.location}` : '';
                $(this).html(text);
              } else if (caseinfo == "case.clergy_name") {
                let text = (vm.case.clergy && vm.case.clergy.length > 0 && `${vm.case.clergy[0]}` != 'null')  ? `${vm.case.clergy[0]}` : ''
                $(this).html(text);
              } else {
                if (caseinfo && !caseLabel) {
                  let text = vm.$eval(caseinfo);
                  $(this).html(text);
                }
              }
            }
          });
          $("#productBuild p").each(function() {
            
            if($(this).data('caseinfo')) {
              $(this).text('')
              const fieldName =  $(this).data('caseinfo').replace('.details', '')
              let fieldValue = vm.$eval(fieldName)
              if(typeof fieldValue == 'object' && fieldValue != null && fieldValue.length > 1){
                fieldValue = `${fieldValue.reduce((a,b) => a.concat('<br>' + b))}`
              }
              const parent = $(this).parent()
              const fieldHTML = $(this).html(fieldValue)

              $(this).remove()
              $(parent).append(fieldHTML)
            }
          })
        }

        function updateCaseInfo() {
          //If order has case id, then update all product templates with new information
        }

        function done() {
          const id = vm.order.id;
          let order = Object.assign({}, vm.order);
          order.id = undefined;
          order.reset_edited_layouts = true;
          delete order.archived;
          delete order.funeral_home_id;
          delete order.service_start_date
          delete order.service_end_date
          delete order.selected_for_service
          orders.update(id, order).then(response => {
            $location.path("dashboard");
          });
        }

        function showCreateCaseModal() {
          if (vm.order.caseId && vm.builtProduct) {
            alert("A case has already been added to this order.");
          } else {
            $("#new-case-modal").modal("show");
          }
        }

        function showPrintInfo() {
          vm.printInfo.size =
            parseFloat(vm.selectedSize.paper_width) +
            " x " +
            parseFloat(vm.selectedSize.paper_height);

          if (
            vm.selectedSize.paper_width === "8.5" ||
            vm.selectedSize.paper_height === "8.5"
          ) {
            if (vm.printInfo.size === "8.5 x 14") {
              vm.printInfo.size += " inches (US Legal)";
            } else if (vm.printInfo.size === "8.5 x 11") {
              vm.printInfo.size += " inches (US Letter)";
            } else {
              vm.printInfo.size += " inches";
            }
          }

          if (
            parseInt(vm.selectedSize.paper_width) <=
            parseInt(vm.selectedSize.paper_height)
          ) {
            vm.printInfo.orient = "Portrait";
            vm.printInfo.duplex = "Long-Edge binding";
          } else {
            vm.printInfo.orient = "Landscape";
            vm.printInfo.duplex = "Short-Edge binding";
          }
          const product = vm.selectedProduct.name;
          const singles = [
            "Large Candle",
            "Square Candles",
            "Designer Square Candle",
            "Directory Signs",
            "Grave Markers",
            "Small Candle",
            "Small Candles",
            "Picture Frame"
          ];

          if (singles.indexOf(product) != -1) {
            vm.printInfo.duplex = "One-sided / Off";
          }
          vm.printInfo.paper = "Plain";
          $("#print-info-modal").modal("show");
        }

        function resetPrint() {
          vm.printInfo = {};
        }

        function editCase(id) {
          cases.get(id).then(caseResponse => {
            cases.getAllCaseCustomFields(id).then(customFieldResponse => {
              $rootScope.$emit("opencase", {
                case_details: caseResponse.data,
                custom_fields: customFieldResponse.data
              });
              $("#case-modal").modal("show");
            });
          });
        }

        function importCSV() {
          if (vm.order.case_id) {
            alert("A case has already been added to this order.");
          } else {
            $timeout(function() {
              document.getElementById("csvfile").click();
            }, 10);
          }
        }

        function detectFileUpload() {
          $timeout(function() {
            document.getElementById("csvfile").onchange = function() {
              var files = document.getElementById("csvfile").files;
              var file = files[0];
              if (file == null) {
                vm.alertText = "Please select a file";
                $("#alert-modal").modal("show");
              } else {
                getCSVData(file);
                var filename =
                  Math.random()
                    .toString(36)
                    .substring(7) + file.type.replace("/", ".");
              }
            };
          });
        }

        function getCSVData(file) {
          Papa.parse(file, {
            complete: function(results) {
              const keys = results.data[0];
              const values = results.data[1];
              let newCase = {};

              keys.forEach((key, i) => {
                if (values[i].indexOf(",") > 0) {
                  newCase[key] = values[i].split(",");
                } else {
                  newCase[key] = values[i];
                }
              });

              convertcsv.convert(newCase).then(sanitizedCase => {
                cases
                  .create(sanitizedCase)
                  .then(() => {
                    getActiveCases();
                  })
                  .catch(err => {
                    err.data.validation
                      ? alert(
                          `${err.data.message}: ${err.data.validation.map(
                            v => v.message
                          )}`
                        )
                      : alert(err.data.message);
                  });
              });
            }
          });
        }

        function themeScroll(direction, index) {
          let w = $(window).width() - 100 + "px";
          if (index !== "custom") {
            if (direction == "right") {
              $(".card-scroll." + index).animate(
                {
                  scrollLeft: "+=" + w
                },
                "slow"
              );
            }
            if (direction == "left") {
              $(".card-scroll." + index).animate(
                {
                  scrollLeft: "-=" + w
                },
                "slow"
              );
            }
          } else {
            if (direction == "right") {
              $(".card-scroll.custom").animate(
                {
                  scrollLeft: "+=" + w
                },
                "slow"
              );
            }
            if (direction == "left") {
              $(".card-scroll.custom").animate(
                {
                  scrollLeft: "-=" + w
                },
                "slow"
              );
            }
          }
        }

        function getWindowWidth() {
          let w = $(window).width();
          if (w < 1201) vm.showThemeScroll = 4;
          if (w > 1200 && w < 1651) vm.showThemeScroll = 5;
          if (w > 1650 && w < 1961) vm.showThemeScroll = 6;
          if (w > 1961) vm.showThemeScroll = 7;
        }

        function replaceStrong() {
          const inOrder = $location.path().indexOf("order") > -1 ? true : false;
          if (vm.selectedProduct.name === "Register Book: Front Insert") {
            $(".printedarea .outlines").css("outline", "");
          }

          $(".ql-editor")
            .find("*")
            .each(function() {
              let align = $(this).css("text-align");
              this.style.setProperty("text-align", align, "important");
            });

          $(".ql-editor")
            .find("*[class*=ql-color]")
            .each(function() {
              let color = $(this).css("color");
              $(this).css("color", color + "!important");
              let colorClass = "ql-color-" + color;
              $(this).removeClass(colorClass);
            });
          $(".ql-editor")
            .find("*[class*=ql-bg]")
            .each(function() {
              let color = $(this).css("background-color");
              $(this).css("background-color", color);
            });
          $(".ql-editor")
            .find("*[class*=ql-font]")
            .each(function() {
              let font = $(this).css("font-family");
              if (font) {
                if (font == "sans-serif" || font == "Sans Serif")
                  font = "Century Gothic";
                $(this).css("font-family", font);
              } else {
                $(this).css("font-family", "inherit");
              }
            });
          $(".ql-editor").css({
            "box-sizing": "border-box",
            "font-family": "",
            "font-size": "",
            color: ""
          });
          $("#productBuild")
            .find("div.ql-container")
            .each(function() {
              $(this).css("color", "");
              if ($(this).css("font-size") != "1em") {
                $(this).css({ "font-size": "1em" });
              }
              $(this).css("height", $(this)[0].scrollHeight + "px");
            });
          $(".ql-editor")
            .find("*[class*=ql-weight]")
            .each(function() {
              let match = false;
              let weight = $(this).css("font-weight");
              let weightName;
              for (let font of helpers.fonts) {
                if (
                  font.name ==
                  $(this)
                    .css("font-family")
                    .replace(/"/g, "")
                ) {
                  if (font.weights) {
                    for (let weights of font.weights) {
                      if (weights.weight == $(this).css("font-weight")) {
                        match = true;
                        // $(this).removeClass(weightName)
                        $(this).css("font-weight", weights.weight);
                        break;
                      }
                    }
                  }
                }
              }
              if (!match) {
                if (weight == 800) {
                  weightName = "ql-weight-Bold";
                } else if (weight == 700) {
                  weightName = "ql-weight-Bold-Italic";
                } else if (weight == 600) {
                  weightName = "ql-weight-Italic";
                } else {
                  weightName = "";
                }
                $(this).removeClass(weightName);
              }
            });

          $(".ql-editor")
            .find("span:not(:has(cite)), u:not(:has(cite)), s:not(:has(cite))")
            .each(function() {
              let text = $(this)
                .text()
                .replace(
                  "[",
                  '<span style="color: rgba(0,0,0,0); text-shadow: none">[</span>'
                )
                .replace(
                  "]",
                  '<span style="color: rgba(0,0,0,0); text-shadow: none">]</span>'
                )
                .replace(/\r?\n?\r\n/g, "<br />");
              $(this).html(text);
              $compile($(this))(vm);
              $(this).css("white-space", "pre-wrap");
            });
          $(".ql-editor")
            .find("cite")
            .each(function() {
              let text = $(this)
                .text()
                .replace(
                  "[",
                  '<span style="color: rgba(0,0,0,0); text-shadow: none">[</span>'
                )
                .replace(
                  "]",
                  '<span style="color: rgba(0,0,0,0); text-shadow: none">]</span>'
                )
                .replace(/\r?\n?\r\n/g, "<br />");
              $(this).html(text);
              $compile($(this))(vm);
              $(this).css("white-space", "pre-wrap");
            });

          $(".ql-editor p")
            .find("cite[data-multi=true]")
            .each(function() {
              if ($(this).data("columns") == true) {
                $(this).css({
                  width: "50%",
                  display: "inline-block",
                  margin: "0px"
                });
              } else {
                $(this).css("display", "block");
                $(this).css("width", "100%");
              }
            });

          vm.previewLoading = false;
          vm.productLoading = false;
          vm.orderUpdated = true;
          $timeout(function() {
            $("#productBuild").css("visibility", "");
            $("#render").css("display", "none");
          }, 100);
        }

        $(window).on("resize", function() {
          let w = $(window).width();
          if (w < 1201) vm.showThemeScroll = 4;
          if (w > 1200 && w < 1651) vm.showThemeScroll = 5;
          if (w > 1650 && w < 1961) vm.showThemeScroll = 6;
          if (w > 1961) vm.showThemeScroll = 7;
        });

        function copyCSS(source) {
          var dom = $(source).get(0);
          var dest = {};
          var style, prop;
          if (window.getComputedStyle) {
            var camelize = function(a, b) {
              return b.toUpperCase();
            };
            if ((style = window.getComputedStyle(dom, null))) {
              var camel, val;
              if (style.length) {
                for (var i = 0, l = style.length; i < l; i++) {
                  prop = style[i];
                  camel = prop.replace(/\-([a-z])/, camelize);
                  val = style.getPropertyValue(prop);
                  if (val.indexOf("rgb") == -1) {
                    if (
                      val != "auto" &&
                      val != "none" &&
                      val != "normal" &&
                      prop != "text-align" &&
                      prop != "transform" &&
                      prop != "white-space" &&
                      prop.indexOf("animation") == -1
                    )
                      dest[camel] = val;
                  }
                }
              } else {
                for (let prop in style) {
                  camel = prop.replace(/\-([a-z])/, camelize);
                  val = style.getPropertyValue(prop) || style[prop];
                  if (val.indexOf("rgb") == -1) {
                    if (
                      val != "auto" &&
                      val != "none" &&
                      val != "normal" &&
                      prop != "text-align" &&
                      prop != "transform" &&
                      prop != "white-space" &&
                      prop.indexOf("animation") == -1
                    )
                      dest[camel] = val;
                  }
                }
              }
              return dest;
            }
          }
          if ((style = dom.currentStyle)) {
            for (let prop in style) {
              dest[prop] = style[prop];
            }
            return dest;
          }
          if ((style = dom.style)) {
            for (let prop in style) {
              if (typeof style[prop] != "function") {
                dest[prop] = style[prop];
              }
            }
          }
          return dest;
        }

        function getSocket(case_id) {
          var deferred = $q.defer();
          if (socket.connectedSocket && socket.connectedSocket.connected) {
            socket.emit("check_case_connection", socket.data);
            socket.on("case_connection", function(data) {
              deferred.resolve(data);
            });
            return deferred.promise;
          } else {
            socket.disconnect();
            user.get().then(response => {
              socket.connect();
              vm.socketData = {
                user_id: user.user.id,
                case_id: case_id
              };
              socket.emit("check_case_connection", vm.socketData);
              socket.on("case_connection", function(data) {
                deferred.resolve(data);
              });
            });
            return deferred.promise;
          }
        }

        function checkSocket() {
          //LEAVE ONE FOR FUTURE REF
          // socket.emit('check_case_connection', vm.socketData)
          // socket.on('case_connection', function(data){
          // console.log('check', data);
          // 		return data
          // })
        }

        function lockedSocket(data) {
          socket.disconnect();
          vm.lockedCase = data.message;
          $("#case-locked-modal").modal("show");
        }

        function refreshSocket(refresh) {
          if (refresh) {
            getSocket(vm.case.id).then(data => {
              if (data.success) {
                $("#case-check-modal").modal("hide");
                init();
              } else {
                $("#case-check-modal").modal("hide");
                vm.lockedCase = data.message;

                $timeout(function() {
                  $("#case-locked-modal").modal("show");
                }, 200);
              }
            });
          } else {
            $("#case-check-modal").modal("hide");
            $timeout(function() {
              $location.path("/dashboard");
            }, 200);
          }
        }

        const socketUpdateOrderListener = $rootScope.$on("socketUpdateOrder", function() {
          updateOrder();
          $("#case-check-modal").modal("show");
        });

        scope.$on('$destroy', () => socketUpdateOrderListener())

        $(document).mousedown(() => {
          if (socket.connectedSocket) {
            if (socket.connectedSocket.connected) {
              socket.socketTimer = socket.time;
              socket.timeOutConnection();
            }
          }
        });

        //******** end *********//
      }
    };
  }
})();
